.footer-container {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: whitesmoke; }
  .footer-container .footer-top {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6rem 0; }
    .footer-container .footer-top ul {
      margin: unset;
      padding: unset;
      list-style-type: none;
      display: flex;
      flex: 1; }
      .footer-container .footer-top ul li {
        padding: 0 2rem; }
  .footer-container .footer-bottom {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    background-color: black;
    color: white; }
    .footer-container .footer-bottom .disclaimer-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 0 2rem; }
    .footer-container .footer-bottom .digigrow-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 0 2rem; }

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .footer-container .footer-top {
    padding: 2rem 0; }
    .footer-container .footer-top ul {
      flex-direction: column;
      align-items: center; }
      .footer-container .footer-top ul li {
        padding: 1rem 0; }
  .footer-container .footer-bottom {
    justify-content: center; }
    .footer-container .footer-bottom .disclaimer-container {
      padding: 0 2rem; }
    .footer-container .footer-bottom .digigrow-container {
      padding: 0 2rem; }
      .footer-container .footer-bottom .digigrow-container img {
        margin-left: 1rem; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .footer-container .footer-top {
    padding: 4rem 0; }
    .footer-container .footer-top ul {
      flex-direction: row; }
      .footer-container .footer-top ul li {
        padding: 0 1rem; }
  .footer-container .footer-bottom {
    justify-content: center; }
    .footer-container .footer-bottom .disclaimer-container {
      padding: 0 2rem; }
    .footer-container .footer-bottom .digigrow-container {
      padding: 0 2rem; }
      .footer-container .footer-bottom .digigrow-container img {
        margin-left: 1rem; } }

.twoCol-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 1rem; }
  .twoCol-container .container-column {
    display: flex;
    flex: 1 1 40%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 1rem; }
    .twoCol-container .container-column img {
      max-width: 100%; }

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .twoCol-container {
    padding: 0 1rem; }
    .twoCol-container .container-column {
      flex: 1 1 100%; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .twoCol-container .container-column {
    flex: 1 1 40%; } }

.multiCol-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 1rem; }
  .multiCol-container .container-row {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 1rem; }
    .multiCol-container .container-row .container-column {
      display: flex;
      flex-basis: 25%; }
  .multiCol-container .container-bottom {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1rem; }

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .multiCol-container {
    padding: 2rem 1rem; }
    .multiCol-container .container-row {
      flex: 1 1 100%; }
      .multiCol-container .container-row .container-column {
        flex-basis: 100%; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .multiCol-container .container-row {
    flex: 1 1 100%; }
    .multiCol-container .container-row .container-column {
      flex-basis: 25%; } }

.card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  margin: 1rem;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card .image-container {
    display: flex;
    flex-wrap: wrap; }
    .card .image-container img {
      max-width: 100%; }
  .card .body-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-basis: 100%;
    align-items: flex-start; }

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .card {
    flex-basis: 100%; } }
